import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import { blue } from '@mui/material/colors';
import CodesEmitted from './CodesEmitted';
import CodesApplied from './CodesApplied';
import CodesIntrodused from './CodesIntrodused';
import dictionary from "./dictionary";
import codesActions from "./codesActions";

const PaperComponent = props => <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"]'}
>
    <Paper {...props}/>
</Draggable>

export default function CodesDialog(props) {
    const { dialogOpen, closeDialog, rows, selectionModel, setSelectionModel } = props;
    const [selectedStatus, setSelectedStatus] = useState();
    const [codeStatuses, setCodeStatuses] = useState({});
    const selectStatus = (status, cises) => {
        setSelectionModel(cises);
        setSelectedStatus(status);
    }

    useEffect(() => {
        let selectedRows, newCodeStatuses = {};

        if (selectionModel.length) {
            const codesObj = rows.reduce((obj, row) => ({ ...obj, [row.cis]: row }), {});
            selectedRows = selectionModel.map(row => codesObj[row]);
        } else setSelectionModel(rows.map(code => code.cis));

        (selectedRows || rows).forEach(row => {
            if (row.cisStatus in newCodeStatuses) newCodeStatuses[row.cisStatus].push(row.cis);
            else newCodeStatuses[row.cisStatus] = [row.cis];
        }, []);
        const statusesArr = Object.keys(newCodeStatuses);

        setSelectedStatus(statusesArr.length == 1 && statusesArr[0]);
        setCodeStatuses(newCodeStatuses);
    }, [dialogOpen]);

    return <Dialog
        open={dialogOpen}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
    >
        <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{ position: 'absolute', right: 12, top: 12, }}
        >
            <CloseIcon />
        </IconButton>
        {selectedStatus ? <StatusSelector status={selectedStatus} { ...props } /> : <>
            <DialogTitle
                id="draggable-dialog-title"
                sx={{ pr: 8, cursor: 'move' }}
            >
                Действия с выбранными кодами
            </DialogTitle>
            <DialogContent dividers>
                <Typography sx={{ p: 3 }}>
                    У выбранных кодов ({selectionModel.length}) разные статусы.
                    Выберите нужный
                </Typography>
                <List sx={{ pt: 0 }}>
                    {Object.entries(codeStatuses).map(([key, val]) => (
                        <ListItem button onClick={ev => selectStatus(key, val)} key={key}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                    <AddIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={
                                dictionary.cisStatus[key] + ' (' + val.length + ')'
                            }/>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </>}
    </Dialog>
}

function StatusSelector(props) {
    const { closeDialog, selectionModel, setLoading, status } = props;
    const { setUrlOpts, user } = useOutletContext();
    const ruStatus = dictionary.cisStatus[status];
    const inn = user.oTokenInfo.inn;
    const [production_date, setDate] = useState(new Date().toISOString().slice(0, 10));

    function fActions(oTarget, oFormData = { once : { production_date } }) {
        oTarget = oTarget.closest('[name]');
        if (!oTarget) return;
        closeDialog();
        setLoading(true);
        codesActions(oTarget.name, selectionModel, oFormData, user).then(() => {
            if (oTarget.name.slice(-4) == 'Sign') {
                const aWaitPrev = JSON.parse(localStorage.waitStatusChange || "[]");
                localStorage.waitStatusChange = JSON.stringify(aWaitPrev.concat(selectionModel.map(cis => ({ cis, status }))));
                setUrlOpts(prev => ({ ...prev }));
            }
            setLoading(false);
        });
    }

    switch (status) {
        case 'EMITTED': return <CodesEmitted { ...{ ...props, fActions }}>
            <CodesCounter { ...{ ...props, production_date, setDate }} /></CodesEmitted>

        case 'APPLIED': return <CodesApplied { ...{ ...props, inn, fActions }}>
            <CodesCounter { ...{ ...props, ruStatus }} /></CodesApplied>

        case 'INTRODUCED': return <CodesIntrodused { ...{ ...props, inn, fActions }}>
            <CodesCounter { ...{ ...props, ruStatus }} /></CodesIntrodused>

        default: return <DialogContent dividers>
            <Typography sx={{ p: 3 }}>
                Функионал для статуса "{ ruStatus }" ещё не реализован.
            </Typography>
        </DialogContent>
    }
}

function CodesCounter(props) {
    const { rows, selectionModel, setSelectionModel, ruStatus, production_date, setDate } = props;
    const [selectionBase] = useState(selectionModel.length ? selectionModel : rows.map(code => code.cis));
    const codesCountMax = selectionBase.length;
    const countChange = ev => setSelectionModel(selectionBase.slice(0, ev.target.value));

    return <Grid container spacing={3} alignItems="center">
        {ruStatus ? <Grid item xs={3}>
            <TextField
                label='Статус'
                name='status'
                value={ruStatus}
            />
        </Grid> : <Grid item>
            <TextField
                type="date"
                variant="outlined"
                label='Дата производства'
                value={production_date}
                onChange={ev => setDate(ev.target.value)}
            />
        </Grid>}
        <Grid item xs>
            <Slider
                min={1}
                max={codesCountMax}
                name='codesCount'
                value={selectionModel.length}
                onChange={countChange}
            />
        </Grid>
        <Grid item xs={3}>
            <TextField
                label="Количество"
                type="number"
                name='codesCount'
                value={selectionModel.length}
                inputProps={{ min: 1, max: codesCountMax }}
                onChange={countChange}
                onClick={ev => ev.target.select()}
            />
        </Grid>
    </Grid>
}