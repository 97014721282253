import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PrintIcon from '@mui/icons-material/Print';
import Upload from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Form from '@rjsf/mui';
import validator from "@rjsf/validator-ajv8";
import dictionary from "./dictionary";

const formatter = new Intl.NumberFormat('ru', {minimumFractionDigits: 2, maximumFractionDigits: 2});

export default function CodesIntrodused(props) {
    const { selectionModel, inn, fActions, children } = props;
    const [formData, setFormData] = useState({
        once: {
            inn,
            buyer_inn: '',
            action_date: new Date().toISOString().slice(0, 10),
            action: 'OWN_USE',
            document_type: 'OTHER',
            document_number: '',
            document_date: new Date().toISOString().slice(0, 10),
            primary_document_custom_name: 'Товарно-транспортная накладная',
        },
        // multi: { product_cost: 11500 }
    });
    const fActionsHandler = ev => formData.once.document_number && fActions(ev.target, formData);

    return <>
        <DialogTitle
            id="draggable-dialog-title"
            sx={{ pr: 8, cursor: 'move' }}
        >
            Вывод из оборота
        </DialogTitle>
        <DialogContent dividers>
            {children}
            <Form
                schema={schema}
                uiSchema={{
                    ...uiSchema,
                    "ui:submitButtonOptions": { "norender": true }
                }}
                formData={formData}
                validator={validator}
                onChange={e => setFormData(e.formData)}
            />
        </DialogContent>
        <DialogActions sx={{ m: 1 }} onClick={fActionsHandler}>
            <Button variant="contained" name='printCodes' title={'Печать'} sx={{ mr: 2 }}>
                <PrintIcon />
            </Button>
            <Upload />{selectionModel.length} код(а/ов)
            <Button variant="contained" name='retireFile' sx={{ mx: 1 }}>Файл</Button>
            <Button variant="contained" name='retireSign'>Подписать и отправить</Button>
        </DialogActions>
    </>
}

const schema = {
    type: "object",
    properties: {
        once: {
            type: "object",
            properties: {
                action: { type: "string" },
                inn: { type: "string" },
                buyer_inn: { type: "string" },
                action_date: { type: "string" },
                document_type: { type: "string" },
                primary_document_custom_name: { type: "string" },
                document_date: { type: "string" },
                document_number: { type: "string" }
            }
        },
        multi: {
            type: "object",
            properties: {
                product_cost: { type: "string" },
            }
        }
    }
};

const uiSchema = { "ui:field" : RetireField }

function RetireField(props) {
    const { once, multi } = props.formData;
    const [cost, setCost] = useState(multi ? formatter.format(multi.product_cost / 100) : '');
    const [aBuyers] = useState(dictionary.buyers);

    const renderOption = (props, option) => <Box component="li" {...props}>
        {option.name + ' ' + option.inn}
    </Box>

    function onChangeBuyer(ev, oBuyer) {
        changeHandler({ target: { name: 'buyer_inn', value: oBuyer.inn } });
    }

    function changeHandler(ev) {
        const name = ev.target.name, val = ev.target.value;
        const newData = { once: { ...once }, multi: { ...multi } };

        if (!name) return;
        if (name != 'product_cost') newData.once[name] = val;
        else if (ev.type == 'blur') {
            const sCost = formatter.format(parseInt(val));

            setCost(sCost);
            newData.multi[name] = +sCost.replace(/\D/, '.') * 100;
        } else return setCost(val);
        props.onChange(newData);
    }

    return <Grid
        container spacing={3} alignItems="center" sx={{ mt: 1 }}
        onChange={changeHandler}
    >
        <Grid item xs={12}>
            <TextField
                label='Причина выбытия'
                name='action'
                fullWidth
                select
                value={once.action}
                onChange={changeHandler}
            >
                {Object.entries(dictionary.actions).map(
                    ([key, title]) => <MenuItem value={key}>{title}</MenuItem>
                )}
            </TextField>
        </Grid>
        <Grid item xs={12}>
            <Autocomplete
                fullWidth
                disableClearable
                id="combo-box-buyers"
                options={aBuyers}
                getOptionLabel={(option) => option.name + ' ' + option.inn}
                renderOption={renderOption}
                renderInput={(params) => <TextField {...params} label="Выберите покупателя" />}
                onChange={onChangeBuyer}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField
                label='ИНН участника оборота товаров'
                name='inn'
                fullWidth
                value={once.inn}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField
                label='ИНН покупателя'
                name='buyer_inn'
                fullWidth
                value={once.buyer_inn}
            />
        </Grid>
        <Grid item xs={4}>
            <TextField
                type="date"
                label='Дата вывода'
                name='action_date'
                fullWidth
                value={once.action_date}
            />
        </Grid>
        <Grid item xs={8}>
            <TextField
                label='Тип первичного документа'
                name='document_type'
                fullWidth
                select
                value={once.document_type}
                onChange={changeHandler}
            >
                {Object.entries(dictionary.documentTypes).map(
                    ([key, title]) => <MenuItem value={key}>{title}</MenuItem>
                )}
            </TextField>
        </Grid>
        <Grid
            item xs={12}
            sx={{ display: once.document_type == 'OTHER' ? '' : 'none' }}
        >
            <TextField
                label='Наименование первичного документа'
                name='primary_document_custom_name'
                value={once.primary_document_custom_name}
                fullWidth
            />
        </Grid>
        <Grid item xs={4}>
            <TextField
                type="date"
                label='Дата документа'
                name='document_date'
                value={once.document_date}
                fullWidth
                variant="outlined"
            />
        </Grid>
        <Grid item xs>
            <TextField
                type="text"
                label='Номер документа'
                name='document_number'
                value={once.document_number}
                variant="outlined"
                fullWidth
                error={!once.document_number}
                autoComplete="off"
            />
        </Grid>
        <Grid item xs={3}>
            <TextField
                label="Цена"
                // type="number"
                name='product_cost'
                value={cost}
                onBlur={changeHandler}
                onClick={ev => ev.target.select()}
            />
        </Grid>
    </Grid>
}
