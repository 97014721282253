import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PrintIcon from '@mui/icons-material/Print';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';

export default function CodesEmitted(props) {
    const { selectionModel, fActions, children } = props;
    const fActionsHandler = ev => fActions(ev.target);

    return <>
        <DialogTitle
            id="draggable-dialog-title"
            sx={{ pr: 8, cursor: 'move', width: 500 }}
        >
            Отчёт о нанесении кодов
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions sx={{ m: 1 }} onClick={fActionsHandler}>
            <Button variant="contained" name='printCodes' title={'Печать'} sx={{ mr: 2 }}>
                <PrintIcon />
            </Button>
            <FormatPaintIcon sx={{ mr: 1 }} />{selectionModel.length} код(а/ов)
            <Button variant="contained" name='utilisationFile' sx={{ mx: 1 }}>Файл</Button>
            <Button variant="contained" name='utilisationSign'>Подписать и отправить</Button>
        </DialogActions>
    </>
}
